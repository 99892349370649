import api from '../../api';

const BASE_URL = '/drilling-design/v2/project';

// PROJECT CRUD
// =================================================

export const fetchProjectList = async (owner) => {
  const response = await api.get(`${BASE_URL}?owner=${owner}`);
  return response.data;
};

export const readProject = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const updateProject = async (projectId, project) => {
  const response = await api.put(`${BASE_URL}/${projectId}`, project);
  return response.data;
};

export const addProject = async (project) => {
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const duplicateProject = async (project) => {
  const response = await api.post(`${BASE_URL}`, project);
  return response.data;
};

export const removeProject = async (projectId) => {
  const response = await api.delete(`${BASE_URL}/${projectId}`);
  return response.data;
};

export const permanentDeleteProjects = async () => {
  const response = await api.delete(`${BASE_URL}`);
  return response.data;
};

export const createDemoProject = async (payload) => {
  const response = await api.post(`${BASE_URL}/demo`, payload);
  return response.data;
};

// PROJECT CONTROLLER
// =================================================

export const updateCasingDesign = async (projectId) => {
  const response = await api.put(`${BASE_URL}/${projectId}/action`);
  return response.data;
};

export const deleteCasingDesign = async (projectId) => {
  const response = await api.delete(`${BASE_URL}/${projectId}/action`);
  return response.data;
};

// PROJECT INPUT AND OUTPUT
// =================================================

export const readProjectGeneralInput = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/general-input`);
  return response.data;
};

export const updateProjectGeneralInput = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/general-input`, obj);
  return response.data;
};

export const readProjectPressure = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/pressure`);
  return response.data;
};

export const updateProjectPressure = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/pressure`, obj);
  return response.data;
};

export const readProjectDrillingProgram = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/drilling-program`);
  return response.data;
};

export const updateProjectDrillingProgram = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/drilling-program`, obj);
  return response.data;
};

export const readCasingDesignOutput = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/casing-design-output`);
  return response.data;
};

export const updateCasingDesignOutput = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/casing-design-output`, obj);
  return response.data;
};

export const readDragTorqueOutput = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/drag-torque-output`);
  return response.data;
};

export const updateDragTorqueOutput = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/drag-torque-output`, obj);
  return response.data;
};

export const readHydraulicOutput = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/hydraulic-output`);
  return response.data;
};

export const updateHydraulicOutput = async (projectId, obj) => {
  const response = await api.put(`${BASE_URL}/${projectId}/hydraulic-output`, obj);
  return response.data;
};

// PROJECT CRUD
// =================================================

export const fetchSensitivityList = async (projectId) => {
  const response = await api.get(`${BASE_URL}/${projectId}/sensitivity`);
  return response.data;
};

export const readSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const updateSensitivity = async (sensitivityId, sensitivity) => {
  const response = await api.put(`${BASE_URL}/sensitivity/${sensitivityId}`, sensitivity);
  return response.data;
};

export const addSensitivity = async (sensitivity) => {
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const duplicateSensitivity = async (sensitivity) => {
  const response = await api.post(`${BASE_URL}/sensitivity`, sensitivity);
  return response.data;
};

export const removeSensitivity = async (sensitivityId) => {
  const response = await api.delete(`${BASE_URL}/sensitivity/${sensitivityId}`);
  return response.data;
};

export const runSensitivity = async (sensitivityId) => {
  const response = await api.post(`${BASE_URL}/sensitivity/${sensitivityId}/run`);
  return response.data;
};

export const readStatusSensitivity = async (sensitivityId) => {
  const response = await api.get(`${BASE_URL}/sensitivity/${sensitivityId}/status`);
  return response.data;
};

// ACTIVITY CRUD
// =================================================

export const readManyActivity = async () => {
  const response = await api.get(`/completion-design/activity`);
  return response.data;
};
