// INITIAL STATE
// ==========================================================================

export const initialState = {
  currentLayout: 'Main', // 'project' || 'sensitivity'

  // Project
  projectIds: [],
  projectsById: {},
  selectedProjectIds: [],
  selectedCasingIds: [],

  // Project General Input
  generalByProjectId: {},
  pressureByProjectId: {},
  drillingProgramByProjectId: {},
  casingDesignByProjectId: {},
  dragTorqueByProjectId: {},
  hydraulicByProjectId: {},

  // Sensitivity
  selectedSensitivityIds: [],
  sensitivityIds: [],
  sensitivitiesById: {},

  selectedHScenarioIds: [],
  selectedCdScenarioIds: [],
  selectedDntScenarioIds: [],
  // Settings
  settings: {}
};

export default initialState;
